import {
  SectionsStatusDropdownEnum,
  SectionsStatusDropdownValuesEnum,
  SectionsTypeDropdownEnum,
  SectionsTypeDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/sections-management/sections-management';

export const SECTION_TYPE = [
  { label: SectionsTypeDropdownEnum.All, value: SectionsTypeDropdownValuesEnum.All },
  { label: SectionsTypeDropdownEnum.MerchantDashboard, value: SectionsTypeDropdownValuesEnum.MerchantDashboard },
  { label: SectionsTypeDropdownEnum.PublicWebsite, value: SectionsTypeDropdownValuesEnum.PublicWebsite },
  { label: SectionsTypeDropdownEnum.Shipping, value: SectionsTypeDropdownValuesEnum.Shipping },
];

export const SECTION_STATUS = [
  { label: SectionsStatusDropdownEnum.All, value: SectionsStatusDropdownValuesEnum.All },
  { label: SectionsStatusDropdownEnum.Published, value: SectionsStatusDropdownValuesEnum.Published },
  { label: SectionsStatusDropdownEnum.Draft, value: SectionsStatusDropdownValuesEnum.Draft },
];
