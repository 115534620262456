import {
  SectionManagementStatusDropdownEnum,
  SectionManagementStatusDropdownValuesEnum,
  SectionTypeDropdownEnum,
  SectionTypeDropdownValuesEnum,
} from '@/domains/pd-admin/types/components/section-management.type';

export const SECTION_STATUS = [
  { label: SectionManagementStatusDropdownEnum.Published, value: SectionManagementStatusDropdownValuesEnum.Published },
  { label: SectionManagementStatusDropdownEnum.Draft, value: SectionManagementStatusDropdownValuesEnum.Draft },
];

export const SECTION_TYPE = [
  { label: SectionTypeDropdownEnum.MerchantDashboard, value: SectionTypeDropdownValuesEnum.MerchantDashboard },
  { label: SectionTypeDropdownEnum.PublicWebsite, value: SectionTypeDropdownValuesEnum.PublicWebsite },
  { label: SectionTypeDropdownEnum.Shipping, value: SectionTypeDropdownValuesEnum.Shipping },
];
