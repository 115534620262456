import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import { ZidInput, ZidButton, ZidSelect, ZidSelectBody, ZidSelectHeader, ZidSelectOption } from '@zidsa/ui';
import useSectionManagement from '../../helpers/components/section-management/useSectionManagement';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import Multiselect from 'vue-multiselect';

export default defineComponent({
  components: {
    TabMultiLanguage,
    BaseText,
    ZidInput,
    ZidButton,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    Multiselect,
  },
  props: ['isEdit', 'sectionData'],
  emits: ['onSubmitSectionData'],
  setup(props, ctx) {
    return {
      ...useSectionManagement(ctx, props.isEdit, props.sectionData),
    };
  },
});
