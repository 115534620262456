import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AllSectionsResponseModel from '@/domains/pd-admin/models/administration/sections-management/all-sections-response-model';
import SectionDetailsResponseModel from '@/domains/pd-admin/models/administration/sections-management/section-details-response-model';
import { SectionInfo } from '@/domains/pd-admin/types/administration/sections-management/section-details';

const ENDPOINT_URL = '/market/admin';

export const getAllSections = (): Promise<AllSectionsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/sections`)
    .then((response) => new AllSectionsResponseModel(response))
    .catch((error) => error);
};

export const deleteSection = (sectionID: number): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/sections/${sectionID}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const filterSections = (
  searchValue: string | null,
  sectionType: string | null,
  sectionStatus: string | null,
): Promise<AllSectionsResponseModel | ErrorModel> => {
  const search = `${
    searchValue !== null && searchValue !== ''
      ? `search=${searchValue}${sectionType !== null || sectionStatus !== null ? '&' : ''}`
      : ``
  }`;

  const type = `${sectionType !== null ? `type=${sectionType}${sectionStatus !== null ? '&' : ''}` : ``}`;

  const status = `${sectionStatus !== null ? `${`isDraft=${sectionStatus}`}` : ``}`;

  return httpClient
    .get(`${ENDPOINT_URL}/sections?${search}${type}${status}`)
    .then((response) => new AllSectionsResponseModel(response))
    .catch((error) => error);
};

export const getSectionDetails = (sectionID: number): Promise<SectionDetailsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/sections/${sectionID}`)
    .then((response) => new SectionDetailsResponseModel(response))
    .catch((error) => error);
};

export const updateSectionDetails = (sectionInfo: SectionInfo): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/sections`, {
      ...sectionInfo,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
