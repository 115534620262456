import Vue from 'vue';
import router from '@/router/index';
import { defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import { ZidBreadcrumb, ZidBreadcrumbItem, ZidLoader } from '@zidsa/ui';
import SectionManagement from '../../../../components/section-management/SectionManagement.vue';
import {
  getSectionDetails,
  updateSectionDetails,
} from '../../../../api/administration/sections-management/sections-management';
import SectionDetailsResponseModel from '../../../../models/administration/sections-management/section-details-response-model';

export default defineComponent({
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
    SectionManagement,
    ZidLoader,
  },
  setup(_, ctx) {
    const sectionID = ref(Number(ctx.root.$route.params.id) || null);
    const isLoading = ref(false);
    const sectionName = ref('');
    const section = reactive({ data: null });

    onBeforeMount(async () => {
      if (sectionID.value) {
        isLoading.value = true;
        await fetchSectionDetails();
        isLoading.value = false;
      }
    });

    const fetchSectionDetails = async () => {
      const res = await getSectionDetails(sectionID.value);
      if (!(res instanceof SectionDetailsResponseModel) || !res.section) {
        isLoading.value = false;
        return;
      }
      section.data = res.section;
    };

    const onSubmitSectionData = async (sectionData) => {
      isLoading.value = true;

      const sectionInfo = {
        section_id: sectionData.id,
        title_ar: sectionData.data.sectionNameAr,
        title_en: sectionData.data.sectionNameEn,
        apps: sectionData.data.applications.map((app) => app.id),
        type: Number(sectionData.data.sectionType.value),
        is_draft: Number(sectionData.data.sectionStatus.value),
        order: Number(sectionData.data.sectionOrder),
      };

      const submitRes = await updateSectionDetails(sectionInfo);

      if (submitRes.status !== 'success') {
        if (sectionInfo.section_id) Vue.$toast.error('Could not update section');
        else Vue.$toast.error('Could not create section');
        isLoading.value = false;
        return;
      }

      if (sectionInfo.section_id) {
        await fetchSectionDetails();
        Vue.$toast.success('Section has been updated');
      } else {
        Vue.$toast.success('section has been created');
        router
          .push({
            name: RoutingRouteEnum.Admin_SectionsManagement,
          })
          .catsh((err) => {
            //
          });
      }

      isLoading.value = false;
    };
    return { isLoading, sectionID, section, RoutingRouteEnum, sectionName, onSubmitSectionData };
  },
});
