import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidInput,
  ZidIcon,
  ZidLoader,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import useSectionsManagement from '@/domains/pd-admin/helpers/administration/sections-management/useSectionsManagement';
import {
  SectionResponseDataInterface,
  SectionTypeEnum,
} from '../../../types/administration/sections-management/sections-management';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { deleteSection } from '@/domains/pd-admin/api/administration/sections-management/sections-management';
import Vue from 'vue';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidIcon,
    ZidLoader,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    SearchIcon,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup() {
    const {
      resultPerPage,
      sectionsManagementState,
      originalSections,
      filteredSections,
      displayedSections,
      currentFilterRange,
      isFilterApplied,
      v$,
      isLoadingSectionsList,
      sectionType,
      sectionStatus,
      SectionsManagementSelectProperties,
      SectionsManagementInputProperty,
      isNextButtonDisabled,
      applyPageRangeFilter,
      onPreviousClick,
      onNextClick,
      onInputDataUpdate,
      onSelectionDataChange,
    } = useSectionsManagement();

    const onCreateNewSectionClicked = () => {
      router.push({ name: RoutingRouteEnum.Admin_SectionDetailsManagement }).catch((er) => {
        //
      });
    };

    const onSectionDelete = async (section: SectionResponseDataInterface) => {
      const res = await deleteSection(section.id);
      if (res.status !== 'success') {
        Vue.$toast.error('Could not delete section!');
        return;
      }
      originalSections.list = originalSections.list.filter((originalSection) => originalSection.id !== section.id);
      filteredSections.list = filteredSections.list.filter((originalSection) => originalSection.id !== section.id);
      displayedSections.list = displayedSections.list.filter((originalSection) => originalSection.id !== section.id);
      applyPageRangeFilter();
      Vue.$toast.success('Section has been deleted successfully');
    };

    const onSectionEdit = (section: SectionResponseDataInterface) => {
      router
        .push({ name: RoutingRouteEnum.Admin_SectionDetailsManagement, params: { id: `${section.id}` } })
        .catch((er) => {
          //
        });
    };

    return {
      resultPerPage,
      SectionTypeEnum,
      sectionsManagementState,
      originalSections,
      filteredSections,
      displayedSections,
      currentFilterRange,
      isFilterApplied,
      v$,
      isLoadingSectionsList,
      sectionType,
      sectionStatus,
      SectionsManagementSelectProperties,
      SectionsManagementInputProperty,
      isNextButtonDisabled,
      onPreviousClick,
      onNextClick,
      onInputDataUpdate,
      onSelectionDataChange,
      onCreateNewSectionClicked,
      onSectionDelete,
      onSectionEdit,
    };
  },
});
