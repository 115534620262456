import { render, staticRenderFns } from "./SectionsManagement.vue?vue&type=template&id=ba75885c&scoped=true&"
import script from "./sectionsManagement.ts?vue&type=script&lang=ts&"
export * from "./sectionsManagement.ts?vue&type=script&lang=ts&"
import style0 from "./sectionsManagement.scss?vue&type=style&index=0&id=ba75885c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba75885c",
  null
  
)

export default component.exports