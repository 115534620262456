import ApplicationModel from '@/api/models/ApplicationModel';

export interface SectionsManagementStateInterface {
  selectedResultPerPage: Record<string, string>;
  sectionType: Record<string, string>;
  sectionStatus: Record<string, string>;
  searchValue: string;
}

export enum SectionsManagementInputProperty {
  SearchValue = 'searchValue',
}

export enum SectionsManagementSelectProperties {
  SelectedResultPerPage = 'selectedResultPerPage',
  SectionType = 'sectionType',
  SectionStatus = 'sectionStatus',
}

export enum SectionsTypeDropdownEnum {
  All = 'All',
  MerchantDashboard = 'Merchant Dashboard',
  PublicWebsite = 'Public Website',
  Shipping = 'Shipping',
}

export enum SectionsTypeDropdownValuesEnum {
  All = '',
  MerchantDashboard = '1',
  PublicWebsite = '2',
  Shipping = '3',
}

export enum SectionsStatusDropdownEnum {
  All = 'All',
  Published = 'Published',
  Draft = 'Draft',
}

export enum SectionsStatusDropdownValuesEnum {
  All = '',
  Published = '0',
  Draft = '1',
}

export interface SectionResponseDataInterface {
  id: number;
  title: {
    ar: string;
    en: string;
  };
  description: {
    ar: string;
    en: string;
  };
  icon: null | string;
  isDraft: boolean;
  total_apps: number;
  type: SectionTypeEnum;
}

export enum SectionTypeEnum {
  MerchantDashboard = 1,
  PublicWebsite = 2,
  Shipping = 3,
}
