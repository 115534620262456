import BaseModel from '@/api/models/BaseModel';
import { SectionResponseDataInterface } from '@/domains/pd-admin/types/administration/sections-management/sections-management';

class AllSectionsResponseModel extends BaseModel {
  public 'apps-sections': SectionResponseDataInterface[];
  constructor(arg: any) {
    super(arg);
    this['apps-sections'] = arg['apps-sections'];
  }
}

export default AllSectionsResponseModel;
