import ApplicationModel from '@/api/models/ApplicationModel';

export interface SectionManagementStateInterface {
  sectionNameEn: string;
  sectionNameAr: string;
  applications: ApplicationModel[];
  sectionType: Record<string, string>;
  sectionOrder: string;
  sectionStatus: Record<string, string>;
}

export enum SectionManagementStatusDropdownEnum {
  Published = 'Published',
  Draft = 'Draft',
}

export enum SectionManagementStatusDropdownValuesEnum {
  Published = '0',
  Draft = '1',
}

export enum SectionManagementInputPropertyEnum {
  SectionNameEn = 'sectionNameEn',
  SectionNameAr = 'sectionNameAr',
  SectionOrder = 'sectionOrder',
}

export enum SectionManagementSelectProperties {
  SectionType = 'sectionType',
  SectionStatus = 'sectionStatus',
}

export enum SectionTypeDropdownEnum {
  MerchantDashboard = 'Merchant Dashboard',
  PublicWebsite = 'Public Website',
  Shipping = 'Shipping',
}

export enum SectionTypeDropdownValuesEnum {
  MerchantDashboard = '1',
  PublicWebsite = '2',
  Shipping = '3',
}
