import BaseModel from '@/api/models/BaseModel';
import { SectionDetailsDataInterface } from '@/domains/pd-admin/types/administration/sections-management/section-details';

class SectionDetailsResponseModel extends BaseModel {
  public section: SectionDetailsDataInterface;
  constructor(arg: any) {
    super(arg);
    this.section = arg.section;
  }
}

export default SectionDetailsResponseModel;
