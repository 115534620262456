import { reactive, ref, computed, SetupContext, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import {
  SectionManagementInputPropertyEnum,
  SectionManagementSelectProperties,
  SectionManagementStateInterface,
  SectionManagementStatusDropdownEnum,
  SectionManagementStatusDropdownValuesEnum,
  SectionTypeDropdownEnum,
  SectionTypeDropdownValuesEnum,
} from '@/domains/pd-admin/types/components/section-management.type';
import { ARABIC_TEXT, ENGLISH_TEXT } from '@/helpers/regex/text';
import { SECTION_STATUS, SECTION_TYPE } from './dropdownInfo';
import ApplicationModel, { ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';
import { SectionDetailsDataInterface } from '@/domains/pd-admin/types/administration/sections-management/section-details';
import { SectionTypeEnum } from '@/domains/pd-admin/types/administration/sections-management/sections-management';
import { getAllAdminApps } from '@/domains/pd-admin/api/administration/apps-management/apps-management';
import { ApplicationDataRowInterface } from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';
import { POSITIVE_NUMBER } from '@/helpers/regex/numbers';
import ErrorModel from '@/api/models/ErrorModel';

const useSectionManagement = (ctx: SetupContext, isEdit?: boolean, sectionData?: SectionDetailsDataInterface) => {
  const isLoading = ref(false);
  const isSubmittingInfo = ref(false);

  const sectionAppsList = reactive<Record<string, ApplicationModel[]>>({ list: [] });

  const originalApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const filteredApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const sectionManagementState = reactive<SectionManagementStateInterface>({
    sectionNameEn: '',
    sectionNameAr: '',
    applications: [],
    sectionType: {
      label: '',
      value: '',
    },
    sectionOrder: '',
    sectionStatus: {
      label: '',
      value: '',
    },
  });

  const rules = {
    sectionNameEn: {
      required,
      sectionNameEn: helpers.withMessage('Section name is required in Arabic and English', required),
      englishContent: (value: string) => {
        return ENGLISH_TEXT.test(value);
      },
    },
    sectionNameAr: {
      required,
      sectionNameAr: helpers.withMessage('من فضلك ادخل اسم القسم باللغة العربية والانجليزية', required),
      arabicContent: (value: string) => {
        return ARABIC_TEXT.test(value);
      },
    },
    applications: {
      required,
      applications: helpers.withMessage('Please select an application', required),
    },
    sectionType: {
      label: { required },
      value: { required },
    },
    sectionOrder: {
      required,
      sectionOrder: helpers.withMessage('Section order is required in numbers', required),
      numbers: (value: string) => {
        return POSITIVE_NUMBER.test(value);
      },
    },
    sectionStatus: {
      label: { required },
      value: { required },
    },
  };

  const appsList = reactive<Record<string, ApplicationModel[]>>({
    list: [],
  });

  const sectionType = reactive({
    options: SECTION_TYPE,
  });

  const sectionStatus = reactive({
    options: SECTION_STATUS,
  });

  const v$ = useVuelidate(rules, sectionManagementState);

  onBeforeMount(async () => {
    isLoading.value = true;

    const allAppsRes = await getAllAdminApps();

    if (allAppsRes instanceof ErrorModel || !allAppsRes.payload) {
      return;
    }

    if (allAppsRes.payload) {
      sectionAppsList.list = sectionData?.apps ?? [];
      originalApps.list = allAppsRes.payload.filter(
        (app: ApplicationDataRowInterface) => app.status === ApplicationListingStatusProperty.Published,
      );

      updateFilterdAppsListForSelection();
    }

    if (isEdit) {
      sectionManagementState.sectionNameEn = sectionData?.title.en ?? '';
      sectionManagementState.sectionNameAr = sectionData?.title.ar ?? '';
      sectionManagementState.applications = sectionData?.apps ?? [];
      sectionManagementState.sectionOrder = sectionData?.order !== undefined ? `${sectionData?.order}` : `0`;

      switch (sectionData?.type) {
        case SectionTypeEnum.MerchantDashboard:
          sectionManagementState.sectionType = {
            label: SectionTypeDropdownEnum.MerchantDashboard,
            value: `${SectionTypeDropdownValuesEnum.MerchantDashboard}`,
          };

          break;
        case SectionTypeEnum.PublicWebsite:
          sectionManagementState.sectionType = {
            label: SectionTypeDropdownEnum.PublicWebsite,
            value: `${SectionTypeDropdownValuesEnum.PublicWebsite}`,
          };
          break;
        case SectionTypeEnum.Shipping:
          sectionManagementState.sectionType = {
            label: SectionTypeDropdownEnum.Shipping,
            value: `${SectionTypeDropdownValuesEnum.Shipping}`,
          };
          break;

        default:
          sectionManagementState.sectionType = {
            label: '',
            value: '',
          };
          break;
      }

      switch (sectionData?.isDraft) {
        case true:
          sectionManagementState.sectionStatus = {
            label: SectionManagementStatusDropdownEnum.Draft,
            value: `${SectionManagementStatusDropdownValuesEnum.Draft}`,
          };

          break;
        case false:
          sectionManagementState.sectionStatus = {
            label: SectionManagementStatusDropdownEnum.Published,
            value: `${SectionManagementStatusDropdownValuesEnum.Published}`,
          };
          break;

        default:
          sectionManagementState.sectionStatus = {
            label: '',
            value: '',
          };
          break;
      }

      v$.value.$validate();
    }
    isLoading.value = false;
  });

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: SectionManagementInputPropertyEnum,
  ) => {
    v$.value[dataProperty].$touch();
    sectionManagementState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: SectionManagementSelectProperties) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    sectionManagementState[property].label = selectedValue.label;
    sectionManagementState[property].value = selectedValue.value;
  };

  const sectionHeaderMessage = computed(() => {
    if (isEdit) {
      return 'General Data';
    } else {
      return 'Create a new section';
    }
  });

  const sectionDescriptionMessage = computed(() => {
    if (isEdit) {
      return 'Section information and status';
    } else {
      return 'Enter the following information to Create the section';
    }
  });

  const showMessageSectionNameField = computed(() => {
    return (
      (v$.value.sectionNameEn.$dirty || v$.value.sectionNameAr.$dirty) &&
      (v$.value.sectionNameEn.$invalid || v$.value.sectionNameAr.$invalid)
    );
  });

  const updateFilterdAppsListForSelection = () => {
    const filtered = originalApps.list.filter((originalApp) => {
      const index = sectionAppsList.list.findIndex((sectionApp) => {
        return sectionApp.id === originalApp.id;
      });
      if (index === -1 && originalApp.status === ApplicationListingStatusProperty.Published) return true;
      else return false;
    });
    filteredApps.list = [...filtered];
  };

  const onAppRemovedFromSelection = (application: ApplicationModel) => {
    sectionAppsList.list = sectionAppsList.list.filter((app) => app.id !== application.id);
    updateFilterdAppsListForSelection();
  };

  const onSubmitData = () => {
    isSubmittingInfo.value = true;
    ctx.emit('onSubmitSectionData', {
      id: sectionData?.id ?? null,
      data: sectionManagementState,
    });
  };

  const submitBtnText = computed(() => {
    if (isEdit) {
      return 'Edit Section';
    } else {
      return 'Create Section';
    }
  });

  return {
    sectionManagementState,
    sectionType,
    sectionStatus,
    appsList,
    filteredApps,
    isSubmittingInfo,
    v$,
    isLoading,
    SectionManagementInputPropertyEnum,
    SectionManagementSelectProperties,
    sectionHeaderMessage,
    sectionDescriptionMessage,
    showMessageSectionNameField,
    submitBtnText,
    onSelectionDataChange,
    onInputDataChange,
    onSubmitData,
    onAppRemovedFromSelection,
  };
};

export default useSectionManagement;
