import { reactive, onBeforeMount, ref, watch, computed } from '@vue/composition-api';
import {
  SectionResponseDataInterface,
  SectionsManagementInputProperty,
  SectionsManagementSelectProperties,
  SectionsManagementStateInterface,
  SectionsStatusDropdownValuesEnum,
  SectionsTypeDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/sections-management/sections-management';
import useVuelidate from '@vuelidate/core';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import { SECTION_TYPE, SECTION_STATUS } from './dropdownInfo';
import {
  filterSections,
  getAllSections,
} from '@/domains/pd-admin/api/administration/sections-management/sections-management';
import AllSectionsResponseModel from '@/domains/pd-admin/models/administration/sections-management/all-sections-response-model';

const useSectionsManagement = () => {
  const isLoadingSectionsList = ref(false);

  const sectionsManagementState = reactive<SectionsManagementStateInterface>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
    sectionType: {
      label: '',
      value: '',
    },
    sectionStatus: {
      label: '',
      value: '',
    },
    searchValue: '',
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    sectionType: {
      label: {},
      value: {},
    },
    sectionStatus: {
      label: {},
      value: {},
    },
    searchValue: {},
  };

  const v$ = useVuelidate(rules, sectionsManagementState);

  const originalSections = reactive<Record<string, SectionResponseDataInterface[]>>({
    list: [],
  });

  const filteredSections = reactive<Record<string, SectionResponseDataInterface[]>>({
    list: [],
  });

  const displayedSections = reactive<Record<string, SectionResponseDataInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterBySectionType = ref(false);

  const isFilterBySectionStatus = ref(false);

  const isFilterBySearch = ref(false);

  const isFilterApplied = ref(false);

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const sectionType = reactive({
    options: SECTION_TYPE,
  });

  const sectionStatus = reactive({
    options: SECTION_STATUS,
  });

  onBeforeMount(async () => {
    isLoadingSectionsList.value = true;
    const res = await getAllSections();
    if (!(res instanceof AllSectionsResponseModel) || !res['apps-sections']) {
      isLoadingSectionsList.value = false;
      return;
    }

    originalSections.list = res['apps-sections'];
    const initialTableRecordCount = res['apps-sections'].length >= 10 ? 10 : res['apps-sections'].length;
    displayedSections.list = res['apps-sections'].slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;

    isLoadingSectionsList.value = false;
  });

  const onInputDataUpdate = (keyUp: KeyboardEvent, dataProperty: SectionsManagementInputProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    v$.value[dataProperty].$touch();
    sectionsManagementState[dataProperty] = target.value;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      fetchByFilters();
    }
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: SectionsManagementSelectProperties,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    sectionsManagementState[property].label = selectedValue.label;
    sectionsManagementState[property].value = selectedValue.value;
  };

  watch(
    () => filteredSections,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch(
    [isFilterBySectionType, isFilterBySectionStatus, isFilterBySearch],
    ([newFilterBySectionType, newFilterBySectionStatus, newFilterBySearch]) => {
      isFilterApplied.value = newFilterBySectionType || newFilterBySectionStatus || newFilterBySearch;
      applyPageRangeFilter();
    },
  );

  watch(
    () => sectionsManagementState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalSections.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedSections.list = !isFilterApplied.value
          ? originalSections.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredSections.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedSections.list = !isFilterApplied.value
          ? originalSections.list.slice(0, originalSections.list.length)
          : filteredSections.list.slice(0, originalSections.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalSections.list.length;
      }
    },
    { deep: true },
  );

  watch(
    () => sectionsManagementState.sectionType,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  watch(
    () => sectionsManagementState.sectionStatus,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  const fetchByFilters = async () => {
    isLoadingSectionsList.value = true;

    const selectedSectionType =
      sectionsManagementState.sectionType.value === SectionsTypeDropdownValuesEnum.All
        ? null
        : sectionsManagementState.sectionType.value;

    const selectedSectionStatus =
      sectionsManagementState.sectionStatus.value === SectionsStatusDropdownValuesEnum.All
        ? null
        : sectionsManagementState.sectionStatus.value;

    const filterRes = await filterSections(
      sectionsManagementState.searchValue,
      selectedSectionType,
      selectedSectionStatus,
    );
    if (!(filterRes instanceof AllSectionsResponseModel) || !filterRes['apps-sections']) {
      isLoadingSectionsList.value = false;
      return;
    }

    originalSections.list = filterRes['apps-sections'];
    const initialTableRecordCount = filterRes['apps-sections'].length >= 10 ? 10 : filterRes['apps-sections'].length;
    displayedSections.list = filterRes['apps-sections'].slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;

    isLoadingSectionsList.value = false;
  };

  const applyPageRangeFilter = () => {
    let newSectionsListEndIndex;

    if (!isFilterApplied.value) {
      newSectionsListEndIndex =
        originalSections.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalSections.list.length;
      displayedSections.list = originalSections.list.slice(currentFilterRange.startIndex, newSectionsListEndIndex);
    } else {
      newSectionsListEndIndex =
        filteredSections.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredSections.list.length;
      displayedSections.list = filteredSections.list.slice(currentFilterRange.startIndex, newSectionsListEndIndex);
    }
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(sectionsManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(sectionsManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(sectionsManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(sectionsManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredSections.list.length;
    } else {
      return currentFilterRange.endIndex >= originalSections.list.length;
    }
  });

  return {
    resultPerPage,
    sectionsManagementState,
    originalSections,
    filteredSections,
    displayedSections,
    currentFilterRange,
    isFilterApplied,
    v$,
    isLoadingSectionsList,
    sectionType,
    sectionStatus,
    SectionsManagementSelectProperties,
    SectionsManagementInputProperty,
    isNextButtonDisabled,
    applyPageRangeFilter,
    onPreviousClick,
    onNextClick,
    onInputDataUpdate,
    onSelectionDataChange,
  };
};

export default useSectionsManagement;
